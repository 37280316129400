<template>
  <studio-card id="pre-insctiption-studio">
    <template slot-scope="{}">
      <v-stepper class="background">
        <!-- PEDIR CITA -->
        <v-row justify="center" v-if="!customer.action">
          <v-col cols="11" md="8">
            <v-row v-if="false">
              <v-col cols="12">
                <h1>{{ $t("precustomer.action") }}</h1>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="6" md="4">
                <v-card @click="customer.action = 'cita'">
                  <v-card-title>{{
                    $t("precustomer.actions.appointment")
                  }}</v-card-title>
                  <v-btn color="primary" fab icon outlined x-large class="my-5">
                    <v-icon>$calendar</v-icon>
                  </v-btn>

                  <v-card-subtitle>
                    {{ $t("precustomer.actions.appointment.text") }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-spacer />
              <v-col cols="6" md="4">
                <v-card
                  @click="
                    customer.action = 'assesoramiento';
                    customer.pre_tattooer = -1;
                  "
                >
                  <v-card-title>
                    {{ $t("precustomer.actions.help") }}
                  </v-card-title>
                  <v-btn
                    color="primary"
                    fab
                    icon
                    outlined
                    x-large
                    class="my-5 text-h2"
                  >
                    ?
                  </v-btn>

                  <v-card-subtitle>
                    {{ $t("precustomer.actions.help.text") }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-spacer />
            </v-row>
          </v-col>
        </v-row>

        <!-- SELECCIONAR "TATUADOR" -->
        <v-row justify="center" v-else-if="!customer.pre_tattooer">
          <v-col cols="11" md="8">
            <v-row>
              <v-btn
                color="primary"
                v-if="assistance"
                @click="customer.action = null"
                outlined
                fab
                icon
                small
                style="position: absolute; top: 17px"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-col cols="12">
                <h1 class="text-h6 text-md-h3">
                  {{ $t("precustomer.tattooer") }}
                </h1>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="6" md="4">
                <v-card
                  @click="
                    customer.pre_tattooer = 1;
                    type ? select(type) : null;
                  "
                >
                  <v-card-title>
                    <h1 class="my-5 text-h2">
                      {{ $t("precustomer.tattooer.yes") }}
                    </h1>
                  </v-card-title>
                  <v-card-subtitle>
                    {{ $t("precustomer.tattooer.yes.text") }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-spacer />
              <v-col cols="6" md="4">
                <v-card
                  @click="
                    customer.pre_tattooer = -1;
                    type ? select(type) : null;
                  "
                >
                  <v-card-title>
                    <h1 class="my-5 text-h2">
                      {{ $t("precustomer.tattooer.no") }}
                    </h1>
                  </v-card-title>
                  <v-card-subtitle>
                    {{ $t("precustomer.tattooer.no.text") }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-spacer />
            </v-row>
          </v-col>
        </v-row>

        <!-- TIPO CITA CITA -->
        <v-row justify="center" v-else-if="!type">
          <v-col cols="11" md="10">
            <v-row>
              <v-btn
                color="primary"
                @click="
                  customer.pre_tattooer = null;
                  customer.action === 'cita' ? null : (customer.action = null);
                "
                outlined
                fab
                icon
                small
                style="position: absolute; top: 17px"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-col cols="12">
                <h1 class="text-h6 text-md-h3">{{ $t("precustomer.type") }}</h1>
              </v-col>
            </v-row>

            <v-row>
              <v-spacer />
              <template v-for="(s, i) in services">
                <v-col cols="6" md="3" class="py-0" :key="`col-${i}`">
                  <v-card @click="select(s)">
                    <v-card-title class="mb-3">
                      {{
                        s.artist_type_id
                          ? $t("appointments.type." + s.name)
                          : s.name
                      }}
                    </v-card-title>

                    <v-avatar
                      color="primary"
                      size="100"
                      class="gris3--text text-h2 text-uppercase"
                    >
                      {{ s.name[0] }}
                    </v-avatar>

                    <v-card-subtitle></v-card-subtitle>
                  </v-card>
                </v-col>
                <v-spacer :key="`spacer-${i}`" />
              </template>
            </v-row>
          </v-col>
        </v-row>
        <br />
        <br />
        <br />
      </v-stepper>
    </template>
  </studio-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "ClientIncription",
  components: {
    StudioCard: () => import("@/components/inscription/studio/Card"),
  },
  mounted() {
    if (!this.$route.params.back)
      this.$store.commit("inscriptions/RESET_CUSTOMER");

    this.fetchStudio();
  },
  data() {
    return {
      type: null,
      services: [],
    };
  },
  computed: {
    ...mapState("inscriptions", ["studioState", "customerState"]),
    studio: {
      get() {
        return this.studioState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_STUDIO", value);
      },
    },
    customer: {
      get() {
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },
    // services() {
    //   if (this.studio) {
    //     let s = this.studio.user.setting.find(
    //       (x) => x.key === "studio_services"
    //     );
    //     if (s) {
    //       return JSON.parse(s.value);
    //     }
    //   }
    //   return [];
    // },
    assistance() {
      let s = this.customer.studio.user.setting.find(
        (x) => x.key === "assistance_appointment"
      );
      if (s) {
        return s.value === "1";
      }

      return false;
    },
  },
  methods: {
    ...mapActions("services", ["getServices"]),
    fetchService() {
      this.getServices({ filters: { studio_id: this.studio.id } }).then(
        (response) => {
          this.services = response.data;
        }
      );
    },
    select(type) {
      this.type = type.name;

      let params = { token: this.$route.params.token, pre: true };

      if (this.customer.pre_tattooer === -1) {
        params.tattooer_id = this.customer.pre_tattooer;
      }
      params.artist = type.name;

      this.customer.type_id = type.id;

      if (this.customer.action === "cita")
        this.$router.push({
          name: "customer_inscription_studio",
          params,
        });
      else
        this.$router.push({
          name: "customer_inscription_studio_asesoramiento",
          params,
        });
    },
    ...mapActions("studios", ["getStudioByToken"]),
    fetchStudio() {
      this.getStudioByToken(this.$route.params.token).then((response) => {
        this.studio = response;
        this.$set(this.customer, "studio", response);
        this.$store.commit("inscriptions/SET_CUSTOMER", this.customer);
        this.processed = true;
        if (this.services.length === 1) {
          this.type = this.services[0].name;
        }
        if (!this.assistance) this.customer.action = "cita";
        this.fetchService();
      });
    },
  },
};
</script>

<style lang="sass">
#pre-insctiption-studio
  .background
    min-height: calc( 100vh - 300px )
  .v-card
    text-align: center
    margin-top: 15px
    height: 86%
    .v-card__title
      display: inline-block
      white-space: nowrap
      width: 100%
    &:hover
      border: 1px solid var(--v-primary-base) !important
    .v-btn--fab.v-size--x-large
      height: 100px
      width: 100px
      .v-icon
        height: 50px
        font-size: 50px
        width: 50px
  &.final
    height: 100vh

    .v-responsive__sizer
      display: none !important
</style>